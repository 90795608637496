import {
  Typography,
  Container,
  Box,
  Divider,
  Button,
} from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import React from 'react'
import { Link } from 'react-router-dom'
import LoginForm from 'components/02-molecules/LoginForm/LoginForm';
import { UserClaims } from '@okta/okta-auth-js';

export const OktaLogin = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [sessionToken, setSessionToken] = useState<string | undefined>();
  const [userInfo, setUserInfo] = useState<UserClaims | null>(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const handleLogin = (username: string, password: string) => {
    oktaAuth
      .signInWithCredentials({ username, password })
      .then((res) => {
        if (res) {
          const sessionToken = res.sessionToken;
          setSessionToken(sessionToken);
          // sessionToken is a one-use token, so make sure this is only called once
          oktaAuth.signInWithRedirect({ sessionToken });
        }
      })
      .catch((err) => toast.error('Error signing in:', err));
  };

  const handleLogout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      toast.error('Error signing out:', err)
    }
  };

  if (sessionToken || authState.isPending) {
    // Hide form while sessionToken is converted into id/access tokens
    return <>Loading...</>;
  } else if (authState.isAuthenticated && userInfo) {
    return (
      <>
        <Typography>
          Thanks You are now signed in. In the real demo, this will
          redirect you back to your client app.
        </Typography>
        <pre>
          <ul>
            {Object.keys(userInfo).map((key, i) => (
              <li>
                <strong>{key}: </strong>
                <span>{userInfo[key]}</span>
              </li>
            ))}
          </ul>
        </pre>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLogout}
        >
          Logout
        </Button>

      </>
    )
  } else {
    return (
      <Container maxWidth="sm">
        <Box mb={4}>
          <Typography variant="h3">
            Okta
        </Typography>
          <Typography variant="body1">
            Please provider your admin credentials to get started.
        </Typography>
          <LoginForm handleSubmit={handleLogin} />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <Typography variant="body2">
            <Link to='/forgot-password'>Forgot Password?</Link>
          </Typography>
          <Typography variant="body2">
            New to CIAM? <Link to='/register'>Register Now</Link>
          </Typography>
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Typography variant="body2" className="text-sm text-muted">
          To make Blueprint a more personalized and relevant
          experience, we log user data. By logging in you accept Blueprint's <Link to="/">Terms of Service</Link> and{" "}
          <Link to="/">Privacy Policy</Link>.
        </Typography>
      </Container>
    );
  }
};
