import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { IConfig, useBlueprintCIAM } from 'components/AuthProvider';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderStyle: "solid",
      backgroundColor: "#ccc",
      borderColor: theme.palette.text.secondary,
    },
  })
);

const ConfigDebug = () => {
  const config: IConfig = useBlueprintCIAM();
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <h2>Config Debugger</h2>
        <pre>
          {JSON.stringify(config, null, 2)}
        </pre>
    </div>
  );
};

export default ConfigDebug;
