import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, createMuiTheme, Theme } from '@material-ui/core/styles';
import { ThemeProvider, AppBar, Container, Toolbar, Typography, Button, Grid, Box, Link } from '@material-ui/core';
import theme from 'theme';
import { colors } from 'styles/colors'
import ReactDOM from 'react-dom';

import DLogo from 'assets/images/ddigital.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    logo: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
      color: colors.black,
    },
    headerBtn: {
      textTransform: 'none',
      backgroundColor: 'transparent',
      color: colors.gray,
      border: '2px solid' + colors.gray,
      padding: '.5rem .75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      borderRadius: '.25rem',
      '&:hover': {
        backgroundColor: colors.gray,
        textDecoration: `none`,
        color: colors.white,
        border: '2px solid' + colors.gray,
      },
    },
    headerBtnSec: {
      textTransform: 'none',
      backgroundColor: colors.gray,
      color: colors.white,
      border: '2px solid' + colors.gray,
      padding: '.5rem .75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      borderRadius: '.25rem',
      '&:hover': {
        backgroundColor: colors.gray,
        textDecoration: `none`,
        color: colors.white,
        border: '2px solid' + colors.gray,
      },
    },
    titleLink: {
      color: colors.black,
      textDecoration: `none`,
      '&:hover': {
        textDecoration: `none`,
        color: colors.black,
      },
    },
    Dlogo: {
      padding: `0 0 20px`,
    },
  })
);

export default function GlobalFooter() {

  const classes = useStyles();
  return ReactDOM.createPortal(
    <div className={classes.root}>
      <Box
        py={{ xs: 5 }}
        marginTop={{ xs: 5 }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Box maxWidth="xs">
                  <img width="125" className={classes.Dlogo} src={DLogo} alt="Deloitte Digital" />
                </Box>
                <Typography variant="body2">
                  &copy; 2021 Deloitte Digital
                </Typography>
                <Typography variant="body2">
                  <Link href="https://ciamsgoservice.cipcloudservice.com/construct/privacyPolicy.html">
                    Privacy Policy
                  </Link>
                  |
                  <Link href="https://ciamsgoservice.cipcloudservice.com/construct/TermsandConditions.html">
                    Terms &#38; Conditions
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>,
    document.getElementById("footer")!);
}
