import React, { useEffect } from 'react';

function UseMountedEffect(effect: (arg0: boolean) => any, dependencies: any) {
  const status = { mounted: false }; // mutable status object
  useEffect(() => {
    status.mounted = true;
    // pass the mutable object to the effect callback
    // store the returned value for cleanup
    const cleanUpFn = effect(status.mounted);
    return () => {
      // mutate the object to signal the consumer
      // this effect is cleaning up
      status.mounted = false;
      if (typeof cleanUpFn === 'function') {
        // run the cleanup function
        cleanUpFn();
      }
    };
  }, [...dependencies]);
}

export default UseMountedEffect;
