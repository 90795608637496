import { useReducer, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, TextField, Paper, Grid, Typography } from '@material-ui/core';

//FOR DEMO ONLY - WILL CLEAN 
import React from 'react'
import { Link } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SecurityIcon from '@material-ui/icons/Security';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  root: {
    padding: theme.spacing(3, 2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {

  },
}));

interface profile {
  firstName: string;
  lastName: string;
  email: string;
  login: string;
}

//FOR DEMO
interface State {
  password: string;
  showPassword: boolean;
}

export const OktaRegister = () => {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    //FOR DEMO
    handleChange('password')
  };
  const [profile, setFormInput] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      firstName: '',
      lastName: '',
      email: '',
      login: '',
    }
  );

  let data = {
    profile,
    credentials: {
      password: {
        value: password,
      },
    },
  };

  //FOR DEMO
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('e', e.target.name);
    console.log('value', e.target.value);
    const name = e.target.name;
    const newValue = e.target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    fetch('https://ciamsgo.okta.com/api/v1/users?activate=false', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'SSWS 00ewu7vOy5OkHozh_kWaS8ldWp8hzsRIy4LF1UaJBn',
      },
    })
      .then((response) => response.json())
      .then((response) => console.log('Success:', JSON.stringify(response)))
      .catch((error) => console.error('Error:', error));
    e.currentTarget.reset();
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={10} md={6} lg={4}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h2" >
              Registration
            </Typography>
            <Typography variant="body1">
              All fields are required
            </Typography>

            <Grid item xs={12}>
              <FormControl variant="filled">
                <InputLabel htmlFor="username">Email</InputLabel>
                <FilledInput
                  id="margin-normal"
                  name="email"
                  required
                  defaultValue={profile.email}
                  className={classes.textField}
                  onChange={handleInput}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="filled">
                <InputLabel htmlFor="username">Login</InputLabel>
                <FilledInput
                  id="margin-normal"
                  name="login"
                  required
                  defaultValue={profile.login}
                  className={classes.textField}
                  onChange={handleInput}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="filled">
                <InputLabel htmlFor="password">Password</InputLabel>
                <FilledInput
                  id="margin-normal"
                  name="password"
                  type="password"
                  required
                  value={password}
                  className={classes.textField}
                  onChange={handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid container
              spacing={0}
              direction="row"
              alignItems="center"
              justify="space-between">
              <Grid item xs={6} spacing={0}>
                <Typography variant="body2">
                  <Link to='/'>Back to Login</Link>
                </Typography>
              </Grid>
              <Grid container item xs={6} spacing={0} justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  id="submit"
                  type="submit"
                >
                  Register
                </Button>
              </Grid>
            </Grid>

            <hr className="my-lg" />

            <Typography variant="body2" className="text-sm text-muted">
              To make Blueprint a more personalized and relevant
              experience, we log user data. By logging in you accept
Blueprint's <Link to="/">Terms of Service</Link> and{" "}
              <Link to="/">Privacy Policy</Link>.
</Typography>
          </form>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
