import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

var root = document.getElementById('root');

ReactDOM.render(
  <Router>
    <App show={document.getElementById('root').dataset.show} />
  </Router>,
  root
);
