import { createMuiTheme } from "@material-ui/core";
import { common } from "@material-ui/core/colors";
import { colors } from 'styles/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.blue,
      contrastText: common.white,
    },
    secondary: {
      main: colors.red,
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'regular',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          display: 'block',
          marginBottom: `60px`,
          overflowX: `hidden`,
          height: '100%',
        },
        '#root': {
          position: 'relative',
          minHeight: '100%',
        },
        '#footer': {
          width: '100%',
          position: 'absolute',
          minHeight: '200px',
        }
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiIconButton: {
      root: {
        minWidth: 'unset',
      },
    },
    MuiTypography: {
      // Name of the rule (h1,h2, h3, h4, h5, h6, subtitle1,subtitle2, body1, body2, button, caption, overline)
      h1: {
        fontWeight: 700,
        color: colors.black,
      },
      h2: {
        fontWeight: 700,
        color: colors.black,
      },
      h3: {
        fontWeight: 700,
        color: colors.black,
      },
      body1: {
        marginBottom: '.5rem',
      }
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
    }
  }
});

export default theme;