import React from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';

// NAV
//------------------------------------------------------------------//
import GlobalNav from 'components/03-organisms/GlobalNav/GlobalNav';

// ROUTES
//------------------------------------------------------------------//
import LoginPage from 'components/05-pages/auth/LoginPage';
import RegisterPage from 'components/05-pages/auth/RegisterPage';
import ConfigPage from 'components/05-pages/ConfigPage';
import SuccessPage from 'components/05-pages/auth/SuccessPage';
import ForgotPasswordPage from 'components/05-pages/auth/ForgotPasswordPage';
import LandingPage from 'components/05-pages/LandingPage';
import GlobalFooter from 'components/03-organisms/GlobalFooter';
// ROUTES, AUTH
//------------------------------------------------------------------//
import { ThemeProvider } from '@material-ui/core';
import theme from 'theme';
import { AuthProvider } from 'components/AuthProvider';

// OKTA
//------------------------------------------------------------------//
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import DemoPage from 'components/05-pages/DemoPage';

const config = {
  // Required config
  issuer: `https://ciamsgo.okta.com`,
  // Required for login flow using getWithRedirect()
  clientId: '0oakupxn6OCqZIMMN3l6',
  // redirectUri: window.location.origin + '/callback',
  redirectUri: 'http://localhost:3000/callback',

  // Parse authorization code from hash fragment instead of search query
  responseMode: 'fragment',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,

  // Configure TokenManager to use sessionStorage instead of localStorage
  tokenManager: {
    storage: 'sessionStorage',
  },
};
const oktaAuth = new OktaAuth(config);

const App = (props) => {
  let location = useLocation();
  const history = useHistory();
  const isAuthenticated = window.isAuthenticated || false;
  const loginPath = window.login || '/login';
  const registerPath = window.register || '/register';
  const profilePath = window.profilePath || '/profile';
  const homePath = window.homePath || '/';
  const handleLogout = window.logout;
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  console.error(props);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <GlobalNav
            isAuthenticated={isAuthenticated}
            loginPath={loginPath}
            registerPath={registerPath}
            profilePath={profilePath}
            homePath={homePath}
            handleLogout={handleLogout}
          />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Switch location={location}>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            {/* Changed Profiles from SecureRoute to Route for Forgerock issue  */}
            {/* <Route exact path="/profile" component={ProfilePage} /> */}
            {/* <Route
              exact
              path="/profile/preferences"
              component={PreferencesPage}
            /> */}
            {/* <Route exact path="/profile/consent" component={ConsentPage} /> */}
            <Route path="/callback" component={LoginCallback} />
            <Route exact path="/config" component={ConfigPage} />
            {/* <Route exact path="/demo" component={DemoPage} /> */}
            <Route exact path="/success" component={SuccessPage} />
            <Route default path="/"></Route>
            <Route path="/home"></Route>
          </Switch>
          <GlobalFooter />
        </Security>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
