import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useOktaAuth } from '@okta/okta-react';

import { IConfig, useBlueprintCIAM } from 'components/AuthProvider';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    header: {
      textAlign: 'center',
    },
  })
);

const SuccessPage = () => {
  const { authState, oktaAuth } = useOktaAuth();

  const config: IConfig = useBlueprintCIAM();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className={classes.header}>SuccessPage</h2>
        </Grid>
      </Grid>
    </div>
  );
};

export default SuccessPage;
