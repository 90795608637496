import * as React from 'react';
import { useState } from 'react';
import Logger from '../utils/logger';

// Hooks
import UseMountedEffect from 'hooks/UseMountedEffect';

interface IConfig {
  apiRoot: string;
  debug: boolean;
  okta?: {
    loginEnabled: boolean;
    registerEnabled: boolean;
    domain: string;
    clientId: string;
  };
  forgerock?: {
    loginEnabled: boolean;
    registerEnabled: boolean;
    clientId: string;
    redirectUri: string;
    scope: string;
    baseUrl: string;
  };
}

export const decodeJwt = (token: any) => JSON.parse(atob(token.split('.')[1]));

// Supports reading and parsing a cookie by name
function readCookie(name: string) {
  if (typeof window !== 'undefined') {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  } else {
    return null;
  }
}

export function createCtx<ContextType>() {
  const ctx = React.createContext<ContextType | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c) throw new Error('useCtx must be inside a Provider with a value');
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

// Create UserContext we can consume downstream
const [useBlueprintCIAM, Provider] = createCtx<IConfig>();

function AuthProvider({ children }: any) {
  const config: IConfig = {
    apiRoot:
      process.env.REACT_APP_BLUEPRINT_API_ROOT ||
      'https://blueprints.deloitte.com',
    debug: process.env.REACT_APP_DEBUG === 'true',
  };

  // Forgerock
  if (process.env.REACT_APP_AUTH_FORGEROCK_ENABLED === 'true') {
    config.forgerock = {
      loginEnabled:
        process.env.REACT_APP_AUTH_FORGEROCK_LOGIN_ENABLED === 'true',
      registerEnabled:
        process.env.REACT_APP_AUTH_FORGEROCK_REGISTER_ENABLED === 'true',
      clientId: process.env.REACT_APP_AUTH_FORGEROCK_CLIENTID || '',
      redirectUri: process.env.REACT_APP_AUTH_FORGEROCK_REDIRECTURI || '',
      scope: process.env.REACT_APP_AUTH_FORGEROCK_SCOPE || '',
      baseUrl: process.env.REACT_APP_AUTH_FORGEROCK_BASEURL || '',
    };
  }

  // Okta
  if (process.env.REACT_APP_AUTH_OKTA_ENABLED === 'true') {
    config.okta = {
      loginEnabled: process.env.REACT_APP_AUTH_OKTA_LOGIN_ENABLED === 'true',
      registerEnabled:
        process.env.REACT_APP_AUTH_OKTA_REGISTER_ENABLED === 'true',
      domain: process.env.REACT_APP_AUTH_OKTA_DOMAIN || '',
      clientId: process.env.REACT_APP_AUTH_OKTA_CLIENTID || '',
    };
  }

  const logger = new Logger({
    name: 'AuthProvider',
    isEnabled: config.debug,
  });
  const [jwtToken, setJwtToken] = useState<string | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  UseMountedEffect((mounted: boolean) => {
    (async () => {
      logger.important(`Blueprint Debug`);
      logger.debug(`config`, config);

      logger.debug(`mounting`);

      if (mounted) {
        logger.debug(`mounted`);
      }
    })();

    return () => (mounted = false);
  }, []);

  return <Provider value={{ ...config }}>{children}</Provider>;
}
export { useBlueprintCIAM, AuthProvider };
export type { IConfig };
