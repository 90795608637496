import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { IConfig, useBlueprintCIAM } from 'components/AuthProvider';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { UserClaims } from '@okta/okta-auth-js';
import Button from '@material-ui/core/Button';

import theme from 'theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { TextField, Typography } from "@material-ui/core";

import CssBaseline from '@material-ui/core/CssBaseline';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'baseline',
      display: 'flex',
      padding: '4rem 0',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    header: {
      textAlign: 'center',
    },
  })
);

const ForgotPasswordPage = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | null>(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Sorry, no emails in our system match that, please double check.")
      .required("This field is required"),
  });

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      throw err;
    }
  };

  const config: IConfig = useBlueprintCIAM();
  const classes = useStyles();

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          alignContent="center"
        >
          {config.okta && (
            <>
              <Grid item xs={10} md={6} lg={4}>
                <Typography variant="h1" className="h2">
                  Forgot Password?
            </Typography>
                <Typography variant="body1" className="lead">
                  Enter your username below and we'll send a password reset link to
                  your registered email address.
            </Typography>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={validationSchema}
                  validateOnBlur={false}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      console.log(values);
                      actions.setSubmitting(false);
                      actions.resetForm();
                      toast.success(({ closeToast }) => (
                        <div>
                          An email has been sent to <strong>{values.email}</strong>.
                          <br />
                      Please follow the instructions to reset your password.
                        </div>
                      ));
                    }, 2000);
                  }}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form noValidate>
                      <Field
                        as={TextField}
                        variant="filled"
                        margin="normal"
                        label="Email"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        inputHelp=""
                        inputError={errors.email}
                        hasError={errors.email && touched.email}
                        autoFocus
                        fullWidth
                      />
                      <Grid container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="space-between">
                        <Grid item xs={6} spacing={0}>
                          <Typography variant="body2">
                            <Link to='/'>Back to Login</Link>
                          </Typography>
                          <Typography variant="body2">
                            New to CIAM? <Link to='/register'>Register Now</Link>
                          </Typography>
                        </Grid>
                        <Grid container item xs={6} spacing={0} justify="flex-end">
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            id="submit"
                            type="submit"
                          >
                            Send Instructions
                        </Button>
                        </Grid>
                      </Grid>

                      <hr className="my-lg" />

                      <Typography variant="body2" className="text-sm text-muted">
                        To make Blueprint a more personalized and relevant
                        experience, we log user data. By logging in you accept
                    Blueprint's <Link to="/">Terms of Service</Link> and{" "}
                        <Link to="/">Privacy Policy</Link>.
                  </Typography>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </>
          )}
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default ForgotPasswordPage;
