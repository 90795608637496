import React, { useRef, useEffect } from 'react';
import ConfigDebug from '../../01-atoms/ConfigDebug';

const ConfigPage = () => {
  return (
    <div role='main'>
      <div>
        <ConfigDebug />
      </div>
    </div>
  );
};

export default ConfigPage;