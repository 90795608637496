import React from 'react';
import { IConfig, useBlueprintCIAM } from 'components/AuthProvider';
import { OktaLogin } from 'components/03-organisms/OktaLogin';

const LoginPage = () => {
  const config: IConfig = useBlueprintCIAM();

  return (
    <>
      {config.okta && config.okta.loginEnabled && (
        <OktaLogin />
      )}
    </>
  )
};

export default LoginPage;
