export const colors = {
    blue:"#0070eb",
    indigo:"#6610f2",
    purple:"#6f42c1",
    pink:"#e83e8c",
    orange:"#fd7e14",
    yellow:"#ffc107",
    green:"#28a745",
    teal:"#20c997",
    cyan:"#17a2b8",
    red:"#eb0000",
    white:"#ffffff",
    black:"#000000",
    gray:"#373737",
    mediumGray:"#cccccc",
    lightGray:"#f8f8f8",
    oktaPrimary: "#00297a",
    oktaSecondary: "#004bdf",
    forgePrimary: "#f96700",
    forgeSecondary: "#fea301",
}

export default {
    primary: colors.blue,
};