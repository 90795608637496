import stringColor from 'string-to-color';

export interface ILogger {
  name: string;
  isEnabled: boolean;
}

export default class Logger {
  public name: string;
  public isEnabled: boolean;
  public color: string;

  constructor({ name, isEnabled }: ILogger) {
    this.name = name;
    this.isEnabled = isEnabled;
    this.color = '#ccc';
    // this.color = stringColor(name);
  }

  get enabled() {
    return this.isEnabled;
  }
  set enabled(truthy) {
    this.isEnabled = truthy;
  }

  public info(...args: Array<any>) {
    const style = `color: ${this.color}; font-weight: bold;`;
    if (this.isEnabled) {
      // tslint:disable-next-line: no-console
      console.info(`%c${this.name}\n`, style, ...args);
    }
  }

  public important(message: string) {
    const style = `color: ${this.color}; font-size: 24px; font-weight: bold;`;
    if (this.isEnabled) {
      // tslint:disable-next-line: no-console
      console.log(
        `%c${this.name}:`,
        'color: #999; font-size: 10px; font-weight: bold;'
      );
      // tslint:disable-next-line: no-console
      console.log(`%c${message}`, style);
    }
  }

  public debug(...args: Array<any>) {
    const style = `color: ${this.color}; font-weight: bold;`;
    if (this.isEnabled) {
      // tslint:disable-next-line: no-console
      console.log(`%c${this.name}\n`, style, ...args);
    }
  }

  public error(...args: Array<any>) {
    const style = 'color: white; background: #ce1a43; font-weight: bold;';
    // tslint:disable-next-line: no-console
    console.error(`%c${this.name}\n`, style, ...args);
  }

  public warn(...args: Array<any>) {
    const style = 'color: white; background: #bf7506; font-weight: bold;';
    if (this.isEnabled) {
      // tslint:disable-next-line: no-console
      console.warn(`%c${this.name}\n`, style, ...args);
    }
  }
}
