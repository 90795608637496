import { Box, Button, FilledInput, FormControl, Grid, Input, InputLabel, TextField, Typography } from "@material-ui/core"
import { Form, useFormik } from "formik";
import SecurityIcon from '@material-ui/icons/Security';

type LoginFormProps = {
  handleSubmit: (username: string, password: string) => void;
}

const LoginForm = ({ handleSubmit }: LoginFormProps) => {

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => {
      handleSubmit(values.username, values.password);
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box maxWidth={{ xs: 0, md: 400 }} my={2}>
        <FormControl margin="dense" fullWidth>
          <TextField
            id="username"
            name="username"
            type="text"
            variant="outlined"
            label="Username"
            value={formik.values.username}
            onChange={formik.handleChange}
          />
        </FormControl>
        <FormControl margin="dense" fullWidth>
          <TextField
            id="password"
            name="password"
            type="password"
            variant="outlined"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
        </FormControl>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        id="submit"
        type="submit"
        startIcon={<SecurityIcon />}
      >
        Login
      </Button>
    </form>
  );

}

export default LoginForm;