import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Card, CardMedia, CardActionArea, CardContent } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { colors } from 'styles/colors';
import frbgimage from 'assets/images/frbg.jpg';
import oktabgimage from 'assets/images/oktabg.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      flexGrow: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      display: 'flex',
      padding: '4rem 0',
      [theme.breakpoints.down('md')]: {
        padding: '1rem',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    header: {
      textAlign: 'center',
    },
    cta: {
      display: 'block',
      textAlign: 'center',
      letterSpacing: '3px',
      fontWeight: 600,
      fontSize: 12,
      backgroundColor: 'rgba(0,0,0,.2)'
    },
    title: {
      letterSpacing: '2px',
      color: 'inherit',
    },
    CardBox: {
      color: colors.gray,
      border: 'none',
      boxShadow: 'none',

      '&.okta': {
        //background: 'linear-gradient(45deg, ' + colors.oktaSecondary + ' 30%,' + colors.oktaPrimary + ' 90%)',
        // background: `url(${oktabgimage})`,
        backgroundColor: "#009CDD",
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: colors.white,
      },
      '&.forgerock': {
        // background: `url(${frbgimage})`,
        backgroundColor: "#f96700",
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //background: 'linear-gradient(45deg, ' + colors.forgePrimary + ' 30%,' + colors.forgeSecondary + ' 90%)',
        color: colors.white,
      },
    },
    CardCase: {
      border: 'none',
      borderRadius: '0px',
      boxShadow: '0',
      borderTop: '1px solid' + colors.white,
      backgroundColor: colors.lightGray,
      color: colors.gray,
    },
    cardLink: {
      display: 'block',
      backgroundColor: colors.mediumGray,
      padding: '20px',
      fontSize: '18px',
      color: colors.gray,

      '&:hover': {
        backgroundColor: colors.gray,
        color: colors.white,
        textDecoration: 'none',
      },
    }
  })
);


const DemoPage = (props: { show: string; }) => {
  const classes = useStyles();

  const oktaCase = [
    {
      name: "Login",
      href: "http://caohldivap02.cipcloudservice.com:9090/ciam/saml2/jsp/idpSSOInit.jsp?metaAlias=/ciamusecases/forgerockidp&spEntityID=https%3A%2F%2Fwww.okta.com%2Fsaml2%2Fservice-provider%2Fspanqkajidlbxvwtwdad",
    },
    {
      name: "Register",
      href: "#",
    },
    {
      name: "Profile",
      href: "#",
    },

  ];

  const forgeRockCase = [
    {
      name: "Login",
      href: "http://caohldivap02.cipcloudservice.com:9090/ciam/saml2/jsp/idpSSOInit.jsp?metaAlias=/ciamusecases/forgerockidp&spEntityID=https%3A%2F%2Fwww.okta.com%2Fsaml2%2Fservice-provider%2Fspanqkajidlbxvwtwdad",
    },
    {
      name: "Register",
      href: "#",
    },
    {
      name: "Profile",
      href: "#",
    },
  ];

  return (
    <>
      <div className={classes.root}>
        <Grid container
          spacing={2}
          alignItems="flex-start"
          justify="center"
          alignContent="center">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Card>
              <CardContent className={`${classes.CardBox} okta`}>
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  minHeight='150'
                  textAlign='center'
                >
                  <Typography variant="h1" className={classes.title}>Okta</Typography>
                </Box>
                <Typography className={classes.cta} variant='overline'>
                  Identity for the Internet
               </Typography>
              </CardContent>
              {oktaCase.map((link) => (
                <Card className={classes.CardCase}>
                  <a href={link.href}
                    target="_blank"
                    className={classes.cardLink}
                    aria-label={link.name}
                    title={link.name}>
                    <i><ChevronRightIcon /></i>{link.name}
                  </a>
                </Card>
              ))}
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Card>
              <CardContent className={`${classes.CardBox} forgerock`}>
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  minHeight='150'
                  textAlign='center'
                >
                  <Typography variant="h1" className={classes.title}>Forgerock</Typography>
                </Box>
                <Typography className={classes.cta} variant='overline'>
                  The Identity Platform You Can Trust
               </Typography>
              </CardContent>
              {forgeRockCase.map((link) => (
                <Card className={classes.CardCase}>
                  <a href={link.href}
                    target="_blank"
                    className={classes.cardLink}
                    aria-label={link.name}
                    title={link.name}>
                    <i><ChevronRightIcon /></i>{link.name}
                  </a>
                </Card>
              ))}
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DemoPage;
