import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Button, Box } from '@material-ui/core';

import SVG from 'react-inlinesvg';
import { ReactComponent as Logo } from './icon-layout.svg';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ReactDOM from 'react-dom';

const useStyles = makeStyles((theme) => ({
  homeLink: {
    color: 'unset',
    textDecoration: 'unset',
    '&:hover': {
      color: 'unset',
      textDecoration: 'unset',
    },
  },
  linkContainer: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  profileLink: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
}));

export default function GlobalNav(props: {
  isAuthenticated: any;
  loginPath: string;
  registerPath: string;
  profilePath: string;
  homePath: string;
  handleLogout: any;
}) {
  const classes = useStyles();

  return ReactDOM.createPortal(
    <Container maxWidth="lg">
      <Box display="flex" justifyContent="space-between" py={4}>
        <Link
          to={props.homePath}
          //onClick={() => window.location.reload()}
          className={classes.homeLink}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Logo />
            </Box>
            <Typography variant="h6">Construct</Typography>
          </Box>
        </Link>
        <Box
          className={classes.linkContainer}
          display="flex"
          alignItems="center"
        >
          {props.isAuthenticated ? (
            <>
              {/* <Link
                to={props.profilePath}
                onClick={() => window.location.reload()}
                className={classes.profileLink}
              > */}
                <a 
                  href={props.profilePath}
                  className={classes.profileLink}
                >
                <AccountCircleIcon fontSize="large" color="primary" />
                </a>
              {/* </Link> */}
              <Button
                variant="contained"
                color="primary"
                onClick={props.handleLogout}
              >
                Logout
              </Button>
            </>
          ) : (
              <>
                <Button variant="outlined" color="primary" href={props.loginPath}>
                  Login
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                  href={props.registerPath}
                >
                  Register
              </Button>
              </>
            )}
        </Box>
      </Box>
    </Container>,
    document.getElementById('header')!
  );
}
