import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { IConfig, useBlueprintCIAM } from 'components/AuthProvider';
import { OktaRegister } from 'components/03-organisms/OktaRegister';
import { useOktaAuth } from '@okta/okta-react';
import { UserClaims } from '@okta/okta-auth-js';
import theme from 'theme';

import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'baseline',
      display: 'flex',
      padding: '4rem 0',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    header: {
      textAlign: 'center',
    },
  })
);

const RegisterPage = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | null>(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      throw err;
    }
  };

  const config: IConfig = useBlueprintCIAM();
  const classes = useStyles();

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          alignContent="center"
        >

          {config.okta && (
            <>
              {config.okta.registerEnabled && <OktaRegister />}
            </>
          )}
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default RegisterPage;
